import React, { useState, useRef } from "react";
import "./Video.css";

const VideoSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const videoRef = useRef(null);

  // Функция для переключения воспроизведения
  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Функция для изменения громкости
  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  return (
    <div className="video-section">
      <h2>Видео группы Люто</h2>

      {/* Сетка для видео */}
      <div className="video-grid">
        {/* Локальные видео */}
        <div className="local-video">
          <video
            ref={videoRef}
            src="/videos/В очереди.mp4"
            controls
            preload="metadata"
            poster="/images/video-poster.jpg"
          >
            Ваш браузер не поддерживает видео-тег.
          </video>
          <p className="video-title">В очереди</p>
        </div>

        <div className="local-video">
          <video
            ref={videoRef}
            src="/videos/Приглашение на Сарагаш.mp4"
            controls
            preload="metadata"
            poster="/images/video-poster.jpg"
          >
            Ваш браузер не поддерживает видео-тег.
          </video>
          <p className="video-title">Приглашаем на Сарагаш</p>
        </div>

        {/* Встраивание видео с YouTube */}
        <div className="embedded-video">
          <iframe
            src="https://www.youtube.com/embed/TTaSFN3Ru60"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <p className="video-title">Концерт в Красноярске</p>
        </div>

        <div className="embedded-video">
          <iframe
            src="https://www.youtube.com/embed/j-LO6-65pMc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <p className="video-title">Промо тур 2023</p>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
