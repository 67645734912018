// import React, { useState, useRef, useEffect } from "react";
import "./MusicPlayer.css";

const Music = () => {
  <></>;

  return (
    <div className="music-section">
      <h4>Контент наполняется...</h4>
    </div>
  );
};

export default Music;
