import React from "react";

function About() {
  return (
    <section id="about" className="py-5 bg-light text-center">
      <div className="container">
        <h2 className="display-5 mb-4">О Нас</h2>
        <p className="lead">
          Группа Люто из Красноярска – это современная рок-группа, исполняющая
          авторские песни. Слушайте Люто и наслаждайтесь настоящей рок-музыкой!
          <br />
          Вокалист творит .....
          <br />
          Бас-гитарист акцентирует .....
          <br />
          Клавишник определяет .....
          <br />
          Ритм сердца заполняет .....
          <br />
          ... и вместе создают, поют, радуют для вас,- группа Люто!
        </p>
      </div>
    </section>
  );
}

export default About;
