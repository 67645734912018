import React from "react";
import MusicPlayerTemp from "../components/MusicPlayerTemp";

function Music() {
  return (
    <div className="music-page">
      <h1>Музыка</h1>
      <MusicPlayerTemp />
    </div>
  );
}

export default Music;
